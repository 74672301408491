// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import Helmet from 'react-helmet';

// Actions
import eventActions from '../../actions/eventActions';

// Selectors
import { getCityConfig } from '../../selectors';
import { getAllEvents } from '../../selectors';

// Misc
import { formatDisplayTime } from '../../modules/helpers';
import IsoMorphUtils from '../../modules/isoMorphUtils';

const BoeingTruck = (props) => {
  const { truck, porterLink } = props;
  return (
    <div className='BoeingTruck'>
      <a href={porterLink}>
        <div className='LocationIcon'>
          { truck.featured_photo &&
            <div
              className="LocationIcon-img"
              style={{ backgroundImage: `url("${IsoMorphUtils.railsContext.imageBase}/${truck.featured_photo}")` }}
            />
          }
        </div>
        <div>
          <h3>{truck.name}</h3>
          <p>{truck.food_categories_string}</p>
        </div>
      </a>
    </div>
  );
}

class BoeingSchedule extends Component {
  constructor(props) {
    super(props);
    const match = props.computedMatch || props.match;
    this.id = match.params.id;
    this.fetchEvents = this.fetchEvents.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.state = {
      onDate: moment(),
    }
  }

  componentDidMount() {
    this.props.clearEvents().then(() => {
      this.fetchEvents();
    });
  }

  fetchEvents() {
    return this.props.fetchEvents({
      boeing_pods: true,
      on_day: this.state.onDate,
      include_bookings: true,
    });
  }

  onPageChange(difference) {
    this.setState((state) => {
      return {
        onDate: state.onDate.add(difference, 'days'),
      }
    }, () => {
      this.fetchEvents();
    });
  }

  render() {
    const { events, cityConfig } = this.props;
    const { onDate } = this.state;
    if (!events) return null;

    const isToday = onDate.isBefore(moment().endOf('day'));

    let eventsList = events.map((event) => {
      const startTime = formatDisplayTime(event.start_time);
      const endTime = formatDisplayTime(event.end_time);

      let trucksList = event.bookings.map((booking) => {
        let truck = booking.truck;
        let porterLink = truck.porter_id ? 'https://order.seattlefoodtruck.com/#/menu/' + truck.porter_id : '#'
        return (<BoeingTruck truck={truck} porterLink={porterLink} key={"truck-" + truck.id} />);
      });

      return (
        <React.Fragment key={"event-" + event.id}>
          <div className='BoeingEvent'>
            <h2>{event.location_name}</h2>
            <p>{event.location_description}</p>
            <p>{startTime} - {endTime}</p>
          </div>

          {trucksList}
        </React.Fragment>
      );
    });

    return (
      <React.Fragment>
        <Helmet>
          <title>Boeing Campus : Everett Schedule</title>
        </Helmet>

        <header>
          <div className='Overlay'>
            <h1>Boeing Campus : Everett Schedule</h1>
            <a className="BoeingCTA" href={"mailto:" + cityConfig.support_email}>Feedback</a>
          </div>
        </header>

        <div className="DatePagination">
          { !isToday &&
            <div className="DatePagination-back" onClick={() => this.onPageChange(-1)}><span className="icon icon-arrow-left"/> Prev Day</div>
          }
          { isToday && <div className="DatePagination-back DatePagination-back--disabled"><span className="icon icon-arrow-left"/> Prev Day</div>}
          <h2 className="DatePagination-title">{onDate.format('dddd, MMMM Do')}</h2>
          <div className="DatePagination-next" onClick={() => this.onPageChange(1)}>Next Day <span className="icon icon-arrow-right"/></div>
        </div>

        {eventsList}

      </React.Fragment>
    );
  }
}

BoeingSchedule.propTypes = {
  fetchEvents: PropTypes.func,
  clearEvents: PropTypes.func,
  events: PropTypes.array,
  match: PropTypes.object,
  computedMatch: PropTypes.object,
  history: PropTypes.object
};

function mapStateToProps(state, props) {
  return {
    events: getAllEvents(state),
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchEvents(options) {
      return dispatch(eventActions.fetch(options));
    },
    clearEvents() {
      return dispatch(eventActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BoeingSchedule));
